/**
 * @generated SignedSource<<418fb9281368395506ea1a45397cb4e6>>
 * @relayHash 5f88937eaa3fd284fa91afa84e436449
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-finding/479.0.0-2024-09-12T15:05:45.473Z/sbRespRootQuery

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PageDisplayEnum = "auctionsSearchAndBrowse" | "cart" | "catalog" | "checkout" | "email" | "favorites" | "mc" | "pdp" | "publication" | "searchAndBrowse" | "similarSold" | "visuallySimilar" | "%future added value";
export type types = "DESIGNER" | "SEARCH" | "SEARCH_HISTORY" | "SELLER" | "USER_PREFERENCE" | "%future added value";
export type sbRespRootQuery$variables = {
  auctionsItemSearchUriRef?: string | null;
  contentModuleId?: string | null;
  disableForceFacet?: boolean | null;
  disableNonParameterizedUrlRedirects?: boolean | null;
  engagedItems?: ReadonlyArray<string | null> | null;
  fetchAuctionsItemSearch: boolean;
  fetchRegionalInfo?: boolean | null;
  fetchSb: boolean;
  fetchTileVideo?: boolean | null;
  fetchUser: boolean;
  first: number;
  followSearchPages?: ReadonlyArray<string | null> | null;
  followSearchTypes?: ReadonlyArray<types | null> | null;
  guestId?: string | null;
  includeSponsoredItems?: boolean | null;
  interiorPhotosCount?: number | null;
  isClient: boolean;
  isTrade: boolean;
  localeOrigin?: string | null;
  originalUrl?: string | null;
  page: number;
  pageDisplayEnum?: PageDisplayEnum | null;
  personalizationId?: string | null;
  previewId?: string | null;
  priceBookName?: string | null;
  regions?: string | null;
  regionsList?: ReadonlyArray<string | null> | null;
  rerankGuestId?: string | null;
  rerankUserId?: string | null;
  showSeller: boolean;
  uriRef?: string | null;
  userCountryCode?: string | null;
  userId?: string | null;
  userZipCode?: string | null;
};
export type sbRespRootQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"SbRespLayout_viewer" | "detectPageExistsAndRedirect_viewer">;
  };
};
export type sbRespRootQuery = {
  response: sbRespRootQuery$data;
  variables: sbRespRootQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "auctionsItemSearchUriRef"
},
v1 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "contentModuleId"
},
v2 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "disableForceFacet"
},
v3 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "disableNonParameterizedUrlRedirects"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "engagedItems"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fetchAuctionsItemSearch"
},
v6 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "fetchRegionalInfo"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fetchSb"
},
v8 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "fetchTileVideo"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fetchUser"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "followSearchPages"
},
v12 = {
  "defaultValue": [
    "SEARCH",
    "USER_PREFERENCE"
  ],
  "kind": "LocalArgument",
  "name": "followSearchTypes"
},
v13 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "guestId"
},
v14 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "includeSponsoredItems"
},
v15 = {
  "defaultValue": 10,
  "kind": "LocalArgument",
  "name": "interiorPhotosCount"
},
v16 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isClient"
},
v17 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isTrade"
},
v18 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "localeOrigin"
},
v19 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "originalUrl"
},
v20 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "page"
},
v21 = {
  "defaultValue": "searchAndBrowse",
  "kind": "LocalArgument",
  "name": "pageDisplayEnum"
},
v22 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "personalizationId"
},
v23 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "previewId"
},
v24 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "priceBookName"
},
v25 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "regions"
},
v26 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "regionsList"
},
v27 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "rerankGuestId"
},
v28 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "rerankUserId"
},
v29 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "showSeller"
},
v30 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "uriRef"
},
v31 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "userCountryCode"
},
v32 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "userId"
},
v33 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "userZipCode"
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contemporaryTrackingString",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "browseUrl",
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "categoryCode",
  "storageKey": null
},
v39 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v40 = {
  "alias": null,
  "args": null,
  "concreteType": "Seller",
  "kind": "LinkedField",
  "name": "seller",
  "plural": false,
  "selections": [
    (v34/*: any*/),
    (v39/*: any*/)
  ],
  "storageKey": null
},
v41 = {
  "alias": null,
  "args": null,
  "concreteType": "pdpMetaItems",
  "kind": "LinkedField",
  "name": "pdpMeta",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "topQuery",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v42 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "localizedPdpUrl",
  "storageKey": null
},
v43 = {
  "alias": null,
  "args": null,
  "concreteType": "ItemClassification",
  "kind": "LinkedField",
  "name": "classification",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creationDate",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v44 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v45 = {
  "alias": null,
  "args": null,
  "concreteType": "ItemCreator",
  "kind": "LinkedField",
  "name": "creators",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "attribution",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Creator",
      "kind": "LinkedField",
      "name": "creator",
      "plural": false,
      "selections": [
        (v44/*: any*/),
        (v39/*: any*/)
      ],
      "storageKey": null
    },
    (v39/*: any*/)
  ],
  "storageKey": null
},
v46 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isArt",
  "storageKey": null
},
v47 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "path",
  "storageKey": null
},
v48 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isLinkable",
  "storageKey": null
},
v49 = {
  "alias": null,
  "args": null,
  "concreteType": "LinkData",
  "kind": "LinkedField",
  "name": "linkData",
  "plural": false,
  "selections": [
    (v47/*: any*/),
    (v48/*: any*/)
  ],
  "storageKey": null
},
v50 = {
  "kind": "Literal",
  "name": "page",
  "value": "searchAndBrowse"
},
v51 = [
  (v50/*: any*/)
],
v52 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalResults",
  "storageKey": null
},
v53 = [
  (v52/*: any*/)
],
v54 = {
  "alias": null,
  "args": (v51/*: any*/),
  "concreteType": "AuctionSolution",
  "kind": "LinkedField",
  "name": "relevantAuctionSolution",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasReserve",
      "storageKey": null
    },
    (v39/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reserveMet",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AuctionBidsConnection",
      "kind": "LinkedField",
      "name": "bids",
      "plural": false,
      "selections": (v53/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": "relevantAuctionSolution(page:\"searchAndBrowse\")"
},
v55 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v56 = {
  "alias": null,
  "args": (v51/*: any*/),
  "concreteType": "AuctionLot",
  "kind": "LinkedField",
  "name": "relevantAuctionLot",
  "plural": false,
  "selections": [
    (v55/*: any*/),
    (v39/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    }
  ],
  "storageKey": "relevantAuctionLot(page:\"searchAndBrowse\")"
},
v57 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "textType",
  "storageKey": null
},
v58 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v59 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v60 = [
  (v58/*: any*/),
  (v59/*: any*/)
],
v61 = {
  "alias": null,
  "args": null,
  "concreteType": "ConvertedAmountList",
  "kind": "LinkedField",
  "name": "convertedAmountList",
  "plural": true,
  "selections": (v60/*: any*/),
  "storageKey": null
},
v62 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantityDisplay",
  "storageKey": null
},
v63 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "percentageOff",
  "storageKey": null
},
v64 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amountType",
  "storageKey": null
},
v65 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showPriceLabel",
  "storageKey": null
},
v66 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showPriceVariability",
  "storageKey": null
},
v67 = [
  (v57/*: any*/),
  (v61/*: any*/),
  (v62/*: any*/),
  (v63/*: any*/),
  (v64/*: any*/),
  (v65/*: any*/),
  (v66/*: any*/)
],
v68 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAuctionEnabled",
  "storageKey": null
},
v69 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isStorefrontOnly",
  "storageKey": null
},
v70 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isMultiSku",
  "storageKey": null
},
v71 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isRingItem",
  "storageKey": null
},
v72 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
},
v73 = {
  "alias": null,
  "args": null,
  "concreteType": "VariableAttribute",
  "kind": "LinkedField",
  "name": "variableAttributes",
  "plural": true,
  "selections": [
    (v34/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "VariableAttributeSortType",
      "kind": "LinkedField",
      "name": "variableAttributeData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SwatchSort",
          "kind": "LinkedField",
          "name": "swatchSort",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Swatch",
              "kind": "LinkedField",
              "name": "swatch",
              "plural": false,
              "selections": [
                (v34/*: any*/),
                (v44/*: any*/),
                (v72/*: any*/),
                (v39/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v39/*: any*/)
  ],
  "storageKey": null
},
v74 = {
  "alias": "originalDisplayPrice",
  "args": (v51/*: any*/),
  "concreteType": "ItemDisplayPriceType",
  "kind": "LinkedField",
  "name": "displayPrice",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ConvertedAmountList",
      "kind": "LinkedField",
      "name": "originalConvertedAmountList",
      "plural": true,
      "selections": (v60/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": "displayPrice(page:\"searchAndBrowse\")"
},
v75 = {
  "alias": null,
  "args": null,
  "concreteType": "ItemPricing",
  "kind": "LinkedField",
  "name": "pricing",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FinancialAmount",
      "kind": "LinkedField",
      "name": "retailPrice",
      "plural": false,
      "selections": [
        (v61/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v76 = [
  {
    "kind": "Literal",
    "name": "formatType",
    "value": "MOBILE"
  }
],
v77 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v78 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v79 = {
  "alias": null,
  "args": null,
  "concreteType": "QuickViewDisplayType",
  "kind": "LinkedField",
  "name": "quickViewDisplay",
  "plural": false,
  "selections": [
    (v36/*: any*/),
    {
      "alias": "mobileTitle",
      "args": (v76/*: any*/),
      "kind": "ScalarField",
      "name": "title",
      "storageKey": "title(formatType:\"MOBILE\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QuickViewParagraph",
      "kind": "LinkedField",
      "name": "paragraphs",
      "plural": true,
      "selections": [
        (v77/*: any*/),
        (v78/*: any*/),
        {
          "alias": "mobileText",
          "args": (v76/*: any*/),
          "kind": "ScalarField",
          "name": "text",
          "storageKey": "text(formatType:\"MOBILE\")"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QuickViewCreator",
      "kind": "LinkedField",
      "name": "creators",
      "plural": true,
      "selections": [
        (v44/*: any*/),
        (v49/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QuickViewCategorySegments",
      "kind": "LinkedField",
      "name": "paragraphAttributeNames",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "period",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "origin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "style",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "periodPrefix",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v80 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v81 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "unit",
    "storageKey": null
  },
  (v80/*: any*/)
],
v82 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "addLabel",
      "value": true
    },
    {
      "kind": "Literal",
      "name": "fields",
      "value": [
        "height",
        "width",
        "depth",
        "diameter",
        "length"
      ]
    },
    {
      "kind": "Literal",
      "name": "separator",
      "value": " "
    }
  ],
  "concreteType": "ItemDisplayMeasurementType",
  "kind": "LinkedField",
  "name": "display",
  "plural": true,
  "selections": (v81/*: any*/),
  "storageKey": "display(addLabel:true,fields:[\"height\",\"width\",\"depth\",\"diameter\",\"length\"],separator:\" \")"
},
v83 = {
  "alias": null,
  "args": null,
  "concreteType": "ItemSize",
  "kind": "LinkedField",
  "name": "size",
  "plural": false,
  "selections": [
    (v80/*: any*/)
  ],
  "storageKey": null
},
v84 = {
  "alias": null,
  "args": null,
  "concreteType": "ItemMeasurement",
  "kind": "LinkedField",
  "name": "measurement",
  "plural": false,
  "selections": [
    (v82/*: any*/),
    (v83/*: any*/)
  ],
  "storageKey": null
},
v85 = {
  "alias": "isInEurope",
  "args": [
    {
      "kind": "Literal",
      "name": "regionName",
      "value": "Europe"
    }
  ],
  "kind": "ScalarField",
  "name": "isInRegion",
  "storageKey": "isInRegion(regionName:\"Europe\")"
},
v86 = {
  "alias": null,
  "args": null,
  "concreteType": "Address",
  "kind": "LinkedField",
  "name": "address",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayCountry",
      "storageKey": null
    },
    (v39/*: any*/)
  ],
  "storageKey": null
},
v87 = [
  {
    "kind": "Literal",
    "name": "limit",
    "value": 1
  }
],
v88 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "placeholder",
  "storageKey": null
},
v89 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "smallPath",
  "storageKey": null
},
v90 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "masterOrZoomPath",
  "storageKey": null
},
v91 = {
  "alias": null,
  "args": null,
  "concreteType": "ItemPhotoVersion",
  "kind": "LinkedField",
  "name": "versions",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "webPath",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v92 = {
  "alias": "productPhotos",
  "args": (v87/*: any*/),
  "concreteType": "ItemPhoto",
  "kind": "LinkedField",
  "name": "photos",
  "plural": true,
  "selections": [
    (v88/*: any*/),
    (v89/*: any*/),
    (v90/*: any*/),
    (v91/*: any*/),
    (v39/*: any*/)
  ],
  "storageKey": "photos(limit:1)"
},
v93 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "width",
  "storageKey": null
},
v94 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "link",
  "storageKey": null
},
v95 = {
  "kind": "Variable",
  "name": "guestId",
  "variableName": "guestId"
},
v96 = {
  "kind": "Variable",
  "name": "priceBookName",
  "variableName": "priceBookName"
},
v97 = {
  "kind": "Variable",
  "name": "uriRef",
  "variableName": "uriRef"
},
v98 = {
  "kind": "Variable",
  "name": "userCountryCode",
  "variableName": "userCountryCode"
},
v99 = {
  "kind": "Variable",
  "name": "userId",
  "variableName": "personalizationId"
},
v100 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v101 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlLabel",
  "storageKey": null
},
v102 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v103 = [
  {
    "kind": "Variable",
    "name": "contentModuleId",
    "variableName": "contentModuleId"
  }
],
v104 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v105 = [
  (v104/*: any*/)
],
v106 = [
  (v101/*: any*/)
],
v107 = {
  "kind": "Variable",
  "name": "isTrade",
  "variableName": "isTrade"
},
v108 = [
  (v107/*: any*/),
  (v50/*: any*/),
  (v96/*: any*/)
],
v109 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v110 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "header",
  "storageKey": null
},
v111 = {
  "alias": null,
  "args": null,
  "concreteType": "SellerProfile",
  "kind": "LinkedField",
  "name": "sellerProfile",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "company",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v112 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "linkReference",
  "storageKey": null
},
v113 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v114 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "linkable",
  "storageKey": null
},
v115 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mm",
  "storageKey": null
},
v116 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "us",
  "storageKey": null
},
v117 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hexCode",
  "storageKey": null
},
v118 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "localizedFilterName",
  "storageKey": null
},
v119 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isFurniture",
  "storageKey": null
},
v120 = {
  "alias": null,
  "args": (v108/*: any*/),
  "concreteType": "ItemDisplayPriceType",
  "kind": "LinkedField",
  "name": "displayPrice",
  "plural": true,
  "selections": (v67/*: any*/),
  "storageKey": null
},
v121 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vertical",
  "storageKey": null
},
v122 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "regionName",
  "storageKey": null
},
v123 = {
  "alias": null,
  "args": null,
  "concreteType": "ShipmentQuote",
  "kind": "LinkedField",
  "name": "shipmentQuotes",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isComplimentary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCalculated",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FinancialAmount",
      "kind": "LinkedField",
      "name": "totalAmount",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ConvertedAmounts",
          "kind": "LinkedField",
          "name": "convertedAmounts",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "USD",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ShipmentServiceMethod",
      "kind": "LinkedField",
      "name": "serviceMethod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Region",
          "kind": "LinkedField",
          "name": "region",
          "plural": false,
          "selections": [
            (v122/*: any*/),
            (v39/*: any*/)
          ],
          "storageKey": null
        },
        (v39/*: any*/)
      ],
      "storageKey": null
    },
    (v39/*: any*/)
  ],
  "storageKey": null
},
v124 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 28
  }
],
v125 = {
  "kind": "Literal",
  "name": "addLabel",
  "value": false
},
v126 = {
  "alias": null,
  "args": null,
  "concreteType": "ItemMeasurement",
  "kind": "LinkedField",
  "name": "measurement",
  "plural": false,
  "selections": [
    (v82/*: any*/),
    (v83/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "unit",
          "value": "IN"
        }
      ],
      "concreteType": "ItemConvertedMeasurementsType",
      "kind": "LinkedField",
      "name": "convertedMeasurements",
      "plural": false,
      "selections": [
        (v93/*: any*/)
      ],
      "storageKey": "convertedMeasurements(unit:\"IN\")"
    },
    {
      "alias": "widthDimensions",
      "args": [
        (v125/*: any*/),
        {
          "kind": "Literal",
          "name": "fields",
          "value": [
            "width"
          ]
        }
      ],
      "concreteType": "ItemDisplayMeasurementType",
      "kind": "LinkedField",
      "name": "display",
      "plural": true,
      "selections": (v81/*: any*/),
      "storageKey": "display(addLabel:false,fields:[\"width\"])"
    },
    {
      "alias": "heightDimensions",
      "args": [
        (v125/*: any*/),
        {
          "kind": "Literal",
          "name": "fields",
          "value": [
            "height"
          ]
        }
      ],
      "concreteType": "ItemDisplayMeasurementType",
      "kind": "LinkedField",
      "name": "display",
      "plural": true,
      "selections": (v81/*: any*/),
      "storageKey": "display(addLabel:false,fields:[\"height\"])"
    }
  ],
  "storageKey": null
},
v127 = {
  "alias": "carouselPhotos",
  "args": [
    {
      "kind": "Literal",
      "name": "limit",
      "value": 2
    }
  ],
  "concreteType": "ItemPhoto",
  "kind": "LinkedField",
  "name": "photos",
  "plural": true,
  "selections": [
    (v104/*: any*/),
    (v39/*: any*/),
    (v88/*: any*/),
    (v89/*: any*/),
    (v90/*: any*/)
  ],
  "storageKey": "photos(limit:2)"
},
v128 = {
  "alias": null,
  "args": (v87/*: any*/),
  "concreteType": "viewInRoomPhotos",
  "kind": "LinkedField",
  "name": "viewInRoomPhotos",
  "plural": true,
  "selections": [
    (v104/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "src",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "viewInRoomPhotosCenterPosition",
      "kind": "LinkedField",
      "name": "centerPosition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "top",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "left",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "viewInRoomPhotosComparatorWidth",
      "kind": "LinkedField",
      "name": "comparatorWidth",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pixel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "inches",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "viewInRoomPhotos(limit:1)"
},
v129 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isNewListing",
  "storageKey": null
},
v130 = {
  "condition": "showSeller",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Seller",
      "kind": "LinkedField",
      "name": "seller",
      "plural": false,
      "selections": [
        (v111/*: any*/)
      ],
      "storageKey": null
    }
  ]
},
v131 = [
  (v107/*: any*/)
],
v132 = [
  (v44/*: any*/)
],
v133 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "photo",
  "storageKey": null
},
v134 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subtitle",
  "storageKey": null
},
v135 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mainImage",
  "storageKey": null
},
v136 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "anchorText",
  "storageKey": null
},
v137 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v138 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "itemId",
  "storageKey": null
},
v139 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image",
  "storageKey": null
},
v140 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "values",
  "storageKey": null
},
v141 = {
  "alias": "displayPriceTracking",
  "args": [
    (v107/*: any*/),
    {
      "kind": "Variable",
      "name": "page",
      "variableName": "pageDisplayEnum"
    },
    (v96/*: any*/),
    (v98/*: any*/)
  ],
  "concreteType": "ItemDisplayPriceType",
  "kind": "LinkedField",
  "name": "displayPrice",
  "plural": true,
  "selections": [
    (v61/*: any*/),
    (v64/*: any*/),
    (v57/*: any*/)
  ],
  "storageKey": null
},
v142 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "personalizationType",
  "storageKey": null
},
v143 = {
  "alias": "productPhotos",
  "args": (v87/*: any*/),
  "concreteType": "ItemPhoto",
  "kind": "LinkedField",
  "name": "photos",
  "plural": true,
  "selections": [
    (v89/*: any*/),
    (v91/*: any*/),
    (v39/*: any*/)
  ],
  "storageKey": "photos(limit:1)"
},
v144 = {
  "condition": "fetchTileVideo",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasApprovedVideo",
      "storageKey": null
    }
  ]
},
v145 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 8
  }
],
v146 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publishedDate",
  "storageKey": null
},
v147 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "full",
  "storageKey": null
},
v148 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "source",
  "storageKey": null
},
v149 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "dek",
    "storageKey": null
  },
  (v110/*: any*/),
  (v49/*: any*/),
  (v135/*: any*/)
],
v150 = [
  (v137/*: any*/),
  (v136/*: any*/),
  (v39/*: any*/)
],
v151 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v152 = {
  "kind": "Variable",
  "name": "countryCode",
  "variableName": "userCountryCode"
},
v153 = {
  "kind": "Variable",
  "name": "zipCode",
  "variableName": "userZipCode"
},
v154 = [
  (v152/*: any*/),
  (v153/*: any*/)
],
v155 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "RegionsByZipCode",
    "kind": "LinkedField",
    "name": "regionsByZipCode",
    "plural": true,
    "selections": (v132/*: any*/),
    "storageKey": null
  },
  (v39/*: any*/)
],
v156 = [
  {
    "kind": "Literal",
    "name": "address",
    "value": {
      "country": "us"
    }
  },
  {
    "kind": "Literal",
    "name": "regionBoundaryTypes",
    "value": [
      "METROPOLITAN",
      "DISTRICT",
      "DOMESTIC"
    ]
  },
  {
    "kind": "Literal",
    "name": "regionType",
    "value": "ITEM_LOCATION"
  }
],
v157 = [
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  }
],
v158 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "truncate",
      "value": false
    }
  ],
  "kind": "ScalarField",
  "name": "daysRelativeToExpiration",
  "storageKey": "daysRelativeToExpiration(truncate:false)"
},
v159 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  },
  {
    "kind": "Variable",
    "name": "pages",
    "variableName": "followSearchPages"
  },
  {
    "kind": "Variable",
    "name": "types",
    "variableName": "followSearchTypes"
  }
],
v160 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "zipCode",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "country",
    "storageKey": null
  },
  (v39/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/),
      (v15/*: any*/),
      (v16/*: any*/),
      (v17/*: any*/),
      (v18/*: any*/),
      (v19/*: any*/),
      (v20/*: any*/),
      (v21/*: any*/),
      (v22/*: any*/),
      (v23/*: any*/),
      (v24/*: any*/),
      (v25/*: any*/),
      (v26/*: any*/),
      (v27/*: any*/),
      (v28/*: any*/),
      (v29/*: any*/),
      (v30/*: any*/),
      (v31/*: any*/),
      (v32/*: any*/),
      (v33/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "sbRespRootQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SbRespLayout_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "detectPageExistsAndRedirect_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v32/*: any*/),
      (v22/*: any*/),
      (v13/*: any*/),
      (v28/*: any*/),
      (v27/*: any*/),
      (v9/*: any*/),
      (v30/*: any*/),
      (v19/*: any*/),
      (v10/*: any*/),
      (v20/*: any*/),
      (v18/*: any*/),
      (v17/*: any*/),
      (v29/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v1/*: any*/),
      (v23/*: any*/),
      (v25/*: any*/),
      (v33/*: any*/),
      (v31/*: any*/),
      (v6/*: any*/),
      (v16/*: any*/),
      (v7/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v8/*: any*/),
      (v15/*: any*/),
      (v24/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v14/*: any*/),
      (v26/*: any*/),
      (v21/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "sbRespRootQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "isRelatedCreatorsModuleEnabled",
            "args": [
              {
                "kind": "Literal",
                "name": "feature",
                "value": "relatedCreatorsModule"
              }
            ],
            "kind": "ScalarField",
            "name": "featureFlag",
            "storageKey": "featureFlag(feature:\"relatedCreatorsModule\")"
          },
          {
            "alias": "isPopularSearchesEnabled",
            "args": [
              {
                "kind": "Literal",
                "name": "feature",
                "value": "popularSearches"
              }
            ],
            "kind": "ScalarField",
            "name": "featureFlag",
            "storageKey": "featureFlag(feature:\"popularSearches\")"
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "pageSize",
                "value": 18
              },
              {
                "kind": "Literal",
                "name": "recentlyViewedIds",
                "value": []
              },
              {
                "kind": "Literal",
                "name": "returnAmount",
                "value": 12
              },
              {
                "kind": "Literal",
                "name": "userIds",
                "value": []
              }
            ],
            "concreteType": "Item",
            "kind": "LinkedField",
            "name": "recommendedItems",
            "plural": true,
            "selections": [
              (v34/*: any*/),
              (v35/*: any*/),
              (v36/*: any*/),
              (v37/*: any*/),
              (v38/*: any*/),
              (v40/*: any*/),
              (v41/*: any*/),
              (v42/*: any*/),
              (v43/*: any*/),
              (v45/*: any*/),
              (v46/*: any*/),
              (v49/*: any*/),
              (v54/*: any*/),
              (v56/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "isTrade",
                    "value": false
                  },
                  (v50/*: any*/)
                ],
                "concreteType": "ItemDisplayPriceType",
                "kind": "LinkedField",
                "name": "displayPrice",
                "plural": true,
                "selections": (v67/*: any*/),
                "storageKey": "displayPrice(isTrade:false,page:\"searchAndBrowse\")"
              },
              (v68/*: any*/),
              (v69/*: any*/),
              (v70/*: any*/),
              (v71/*: any*/),
              (v73/*: any*/),
              (v74/*: any*/),
              (v75/*: any*/),
              (v79/*: any*/),
              (v84/*: any*/),
              (v85/*: any*/),
              (v86/*: any*/),
              (v92/*: any*/),
              (v39/*: any*/)
            ],
            "storageKey": "recommendedItems(pageSize:18,recentlyViewedIds:[],returnAmount:12,userIds:[])"
          },
          {
            "alias": "video",
            "args": null,
            "concreteType": "VideoVimeo",
            "kind": "LinkedField",
            "name": "itemVideo",
            "plural": false,
            "selections": [
              {
                "alias": "videoThumb",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "pad",
                    "value": false
                  },
                  {
                    "kind": "Literal",
                    "name": "size",
                    "value": "w295h166"
                  }
                ],
                "kind": "ScalarField",
                "name": "thumbnail",
                "storageKey": "thumbnail(pad:false,size:\"w295h166\")"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "height",
                "storageKey": null
              },
              (v93/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "VideoVimeoFile",
                "kind": "LinkedField",
                "name": "fileByVideoSize",
                "plural": false,
                "selections": [
                  (v94/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "showCreatorDesignTiles",
            "args": [
              {
                "kind": "Literal",
                "name": "feature",
                "value": "sbCreatorDesignTiles"
              }
            ],
            "kind": "ScalarField",
            "name": "featureFlag",
            "storageKey": "featureFlag(feature:\"sbCreatorDesignTiles\")"
          },
          {
            "alias": "isWordpressArticlesEnabled",
            "args": [
              {
                "kind": "Literal",
                "name": "featureName",
                "value": "WP_EDITORIAL"
              }
            ],
            "kind": "ScalarField",
            "name": "isEnabledByLocale",
            "storageKey": "isEnabledByLocale(featureName:\"WP_EDITORIAL\")"
          },
          {
            "alias": "noSoldItemsOnCollectionPage",
            "args": [
              {
                "kind": "Literal",
                "name": "feature",
                "value": "noSoldItemsOnCollectionPage"
              }
            ],
            "kind": "ScalarField",
            "name": "featureFlag",
            "storageKey": "featureFlag(feature:\"noSoldItemsOnCollectionPage\")"
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "disableForceFacet",
                "variableName": "disableForceFacet"
              },
              {
                "kind": "Variable",
                "name": "disableNonParameterizedUrlRedirects",
                "variableName": "disableNonParameterizedUrlRedirects"
              },
              {
                "kind": "Variable",
                "name": "engagedItems",
                "variableName": "engagedItems"
              },
              {
                "kind": "Variable",
                "name": "first",
                "variableName": "first"
              },
              (v95/*: any*/),
              {
                "kind": "Variable",
                "name": "includeSponsoredItems",
                "variableName": "includeSponsoredItems"
              },
              {
                "kind": "Variable",
                "name": "localeOrigin",
                "variableName": "localeOrigin"
              },
              {
                "kind": "Variable",
                "name": "originalUrl",
                "variableName": "originalUrl"
              },
              {
                "kind": "Variable",
                "name": "page",
                "variableName": "page"
              },
              (v96/*: any*/),
              {
                "kind": "Variable",
                "name": "regions",
                "variableName": "regions"
              },
              {
                "kind": "Variable",
                "name": "regionsList",
                "variableName": "regionsList"
              },
              (v97/*: any*/),
              (v98/*: any*/),
              (v99/*: any*/)
            ],
            "concreteType": "ItemSearchQueryConnection",
            "kind": "LinkedField",
            "name": "itemSearch",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayUriRef",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pageType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SearchCorrectionsType",
                "kind": "LinkedField",
                "name": "searchCorrections",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "semanticSearchTerm",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "classificationLevel",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "classificationSearchTerm",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "searchType",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SearchBrowseParsedParam",
                "kind": "LinkedField",
                "name": "appliedFilters",
                "plural": true,
                "selections": [
                  (v100/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SearchBrowseParsedParamValue",
                    "kind": "LinkedField",
                    "name": "values",
                    "plural": true,
                    "selections": [
                      (v101/*: any*/),
                      (v44/*: any*/),
                      (v102/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "vanityUriRef",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "topCategoryL1",
                "storageKey": null
              },
              (v52/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "appliedPersonalizationList",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v103/*: any*/),
                "concreteType": null,
                "kind": "LinkedField",
                "name": "browseContentModule",
                "plural": false,
                "selections": (v105/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SortType",
                "kind": "LinkedField",
                "name": "sort",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SearchBrowseSortCurrentOption",
                    "kind": "LinkedField",
                    "name": "currentOption",
                    "plural": false,
                    "selections": (v106/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SearchBrowseCategoryTiles",
                "kind": "LinkedField",
                "name": "categoryTiles",
                "plural": true,
                "selections": (v105/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Seller",
                "kind": "LinkedField",
                "name": "seller",
                "plural": false,
                "selections": [
                  {
                    "alias": "isRewarded",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "listNames",
                        "value": [
                          "SELLER_REWARDS_1",
                          "SELLER_REWARDS_2"
                        ]
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "isAnchorListsMember",
                    "storageKey": "isAnchorListsMember(listNames:[\"SELLER_REWARDS_1\",\"SELLER_REWARDS_2\"])"
                  },
                  (v39/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "controlEngagedItemIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ItemSearchQueryEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ItemSearchResult",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Item",
                        "kind": "LinkedField",
                        "name": "item",
                        "plural": false,
                        "selections": [
                          (v34/*: any*/),
                          (v39/*: any*/),
                          {
                            "alias": null,
                            "args": (v108/*: any*/),
                            "concreteType": "ItemDisplayPriceType",
                            "kind": "LinkedField",
                            "name": "displayPrice",
                            "plural": true,
                            "selections": [
                              (v57/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": (v87/*: any*/),
                            "concreteType": "ItemPhoto",
                            "kind": "LinkedField",
                            "name": "photos",
                            "plural": true,
                            "selections": [
                              (v91/*: any*/),
                              (v39/*: any*/)
                            ],
                            "storageKey": "photos(limit:1)"
                          }
                        ],
                        "storageKey": null
                      },
                      (v39/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasHiddenSoldItems",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "trackedAbTests",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "MetaData",
                "kind": "LinkedField",
                "name": "meta",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "prev",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "disableIndexing",
                    "storageKey": null
                  },
                  (v36/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "topQueries",
                    "storageKey": null
                  },
                  (v109/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "canonical",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "next",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LanguageAnnotations",
                    "kind": "LinkedField",
                    "name": "languageAnnotations",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "href",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hrefLang",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "microdata",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pageExists",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "unauthorized",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "redirectType",
                "kind": "LinkedField",
                "name": "redirect",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "location",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "condition": "fetchSb",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isL1LandingPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BottomModule",
                    "kind": "LinkedField",
                    "name": "bottomModule",
                    "plural": true,
                    "selections": [
                      (v36/*: any*/),
                      (v109/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "publicationEndDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MetaData",
                    "kind": "LinkedField",
                    "name": "meta",
                    "plural": false,
                    "selections": [
                      (v110/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "subHeader",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "attributeDisplayName",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "publicationStartDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCollectionAuction",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "searchTerm",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SearchCorrectionsType",
                    "kind": "LinkedField",
                    "name": "searchCorrections",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "forceSearchUri",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "originalSearchTerm",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SearchCorrectionSuggestionsValues",
                        "kind": "LinkedField",
                        "name": "suggestions",
                        "plural": true,
                        "selections": [
                          (v100/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "uri",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Seller",
                    "kind": "LinkedField",
                    "name": "seller",
                    "plural": false,
                    "selections": [
                      (v34/*: any*/),
                      (v111/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SearchBrowseParsedParam",
                    "kind": "LinkedField",
                    "name": "appliedFilters",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SearchBrowseParsedParamValue",
                        "kind": "LinkedField",
                        "name": "values",
                        "plural": true,
                        "selections": [
                          (v112/*: any*/),
                          (v113/*: any*/),
                          (v114/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "FilterPropertiesType",
                            "kind": "LinkedField",
                            "name": "properties",
                            "plural": false,
                            "selections": [
                              (v115/*: any*/),
                              (v116/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v117/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "canBeDismissed",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "clearAllLinkReference",
                        "storageKey": null
                      },
                      (v118/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LinkData",
                    "kind": "LinkedField",
                    "name": "breadcrumbs",
                    "plural": true,
                    "selections": [
                      (v78/*: any*/),
                      (v47/*: any*/),
                      (v48/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 20
                      }
                    ],
                    "concreteType": "youMayAlsoLikeConnection",
                    "kind": "LinkedField",
                    "name": "youMayAlsoLike",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "youMayAlsoLikeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ItemSearchResult",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Item",
                                "kind": "LinkedField",
                                "name": "item",
                                "plural": false,
                                "selections": [
                                  (v34/*: any*/),
                                  (v119/*: any*/),
                                  (v36/*: any*/),
                                  (v41/*: any*/),
                                  (v42/*: any*/),
                                  (v43/*: any*/),
                                  (v45/*: any*/),
                                  (v46/*: any*/),
                                  (v49/*: any*/),
                                  (v54/*: any*/),
                                  (v56/*: any*/),
                                  (v120/*: any*/),
                                  (v68/*: any*/),
                                  (v121/*: any*/),
                                  (v123/*: any*/),
                                  (v69/*: any*/),
                                  (v70/*: any*/),
                                  (v71/*: any*/),
                                  (v73/*: any*/),
                                  (v74/*: any*/),
                                  (v75/*: any*/),
                                  (v79/*: any*/),
                                  (v84/*: any*/),
                                  (v85/*: any*/),
                                  (v86/*: any*/),
                                  (v92/*: any*/),
                                  {
                                    "alias": null,
                                    "args": [
                                      {
                                        "kind": "Literal",
                                        "name": "size",
                                        "value": "thumb"
                                      }
                                    ],
                                    "kind": "ScalarField",
                                    "name": "firstPhotoWebPath",
                                    "storageKey": "firstPhotoWebPath(size:\"thumb\")"
                                  },
                                  (v35/*: any*/),
                                  (v37/*: any*/),
                                  (v38/*: any*/),
                                  (v40/*: any*/),
                                  (v39/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v39/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "youMayAlsoLike(first:20)"
                  },
                  {
                    "alias": "soldItems",
                    "args": (v124/*: any*/),
                    "concreteType": "soldResultsConnection",
                    "kind": "LinkedField",
                    "name": "soldResults",
                    "plural": false,
                    "selections": (v53/*: any*/),
                    "storageKey": "soldResults(first:28)"
                  },
                  {
                    "alias": "soldItemResults",
                    "args": (v124/*: any*/),
                    "concreteType": "soldResultsConnection",
                    "kind": "LinkedField",
                    "name": "soldResults",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "soldResultsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ItemSearchResult",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Item",
                                "kind": "LinkedField",
                                "name": "item",
                                "plural": false,
                                "selections": [
                                  (v34/*: any*/),
                                  (v119/*: any*/),
                                  (v36/*: any*/),
                                  (v41/*: any*/),
                                  (v42/*: any*/),
                                  (v43/*: any*/),
                                  (v45/*: any*/),
                                  (v46/*: any*/),
                                  (v49/*: any*/),
                                  (v54/*: any*/),
                                  (v56/*: any*/),
                                  {
                                    "alias": null,
                                    "args": [
                                      (v107/*: any*/),
                                      (v50/*: any*/)
                                    ],
                                    "concreteType": "ItemDisplayPriceType",
                                    "kind": "LinkedField",
                                    "name": "displayPrice",
                                    "plural": true,
                                    "selections": (v67/*: any*/),
                                    "storageKey": null
                                  },
                                  (v68/*: any*/),
                                  (v121/*: any*/),
                                  (v123/*: any*/),
                                  (v69/*: any*/),
                                  (v70/*: any*/),
                                  (v71/*: any*/),
                                  (v73/*: any*/),
                                  (v74/*: any*/),
                                  (v75/*: any*/),
                                  (v79/*: any*/),
                                  (v126/*: any*/),
                                  (v85/*: any*/),
                                  (v86/*: any*/),
                                  (v92/*: any*/),
                                  (v127/*: any*/),
                                  (v128/*: any*/),
                                  (v129/*: any*/),
                                  (v35/*: any*/),
                                  (v37/*: any*/),
                                  (v38/*: any*/),
                                  (v40/*: any*/),
                                  (v39/*: any*/),
                                  (v130/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v39/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "soldResults(first:28)"
                  },
                  {
                    "alias": null,
                    "args": (v131/*: any*/),
                    "concreteType": "SearchBrowsePriceTile",
                    "kind": "LinkedField",
                    "name": "priceTiles",
                    "plural": true,
                    "selections": (v132/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v103/*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "browseContentModule",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "moduleType",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Seller",
                            "kind": "LinkedField",
                            "name": "seller",
                            "plural": false,
                            "selections": [
                              (v111/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SellerPreferences",
                                "kind": "LinkedField",
                                "name": "sellerPreferences",
                                "plural": false,
                                "selections": (v106/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "StorefrontProfile",
                                "kind": "LinkedField",
                                "name": "liveStorefrontProfile",
                                "plural": false,
                                "selections": [
                                  (v55/*: any*/),
                                  (v39/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v39/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v109/*: any*/),
                          (v36/*: any*/),
                          (v133/*: any*/)
                        ],
                        "type": "ContentModuleRegular",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v133/*: any*/),
                          (v36/*: any*/),
                          (v134/*: any*/),
                          (v109/*: any*/),
                          (v94/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "linkText",
                            "storageKey": null
                          }
                        ],
                        "type": "ContentModuleBanner",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v109/*: any*/),
                          (v36/*: any*/),
                          (v135/*: any*/)
                        ],
                        "type": "ContentModuleIconicDesign",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "termsLink",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BrowseContentModuleBannerType",
                            "kind": "LinkedField",
                            "name": "banners",
                            "plural": true,
                            "selections": [
                              (v36/*: any*/),
                              (v109/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "eyeBrowText",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "ctaUrl",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "ctaCopy",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "ctaStyle",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "textAlignment",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "textColor",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "backgroundColor",
                                "storageKey": null
                              },
                              (v135/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "format",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "textPosition",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "bannerImageWidth",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BrowseContentModuleTile",
                            "kind": "LinkedField",
                            "name": "tiles",
                            "plural": true,
                            "selections": [
                              (v72/*: any*/),
                              (v36/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "content",
                                "storageKey": null
                              },
                              (v49/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "LocalizedLinkData",
                                "kind": "LinkedField",
                                "name": "localizedLinkDataList",
                                "plural": true,
                                "selections": [
                                  (v59/*: any*/),
                                  (v49/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "ContentModuleBannerTiles",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RelatedSearch",
                    "kind": "LinkedField",
                    "name": "relatedSearches",
                    "plural": true,
                    "selections": [
                      (v136/*: any*/),
                      (v137/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SearchBrowseCategoryTiles",
                    "kind": "LinkedField",
                    "name": "categoryTiles",
                    "plural": true,
                    "selections": [
                      (v44/*: any*/),
                      (v138/*: any*/),
                      (v137/*: any*/),
                      (v139/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "canFollowSearch",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayMaxNumberOfPages",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SearchBrowseFilter",
                    "kind": "LinkedField",
                    "name": "filters",
                    "plural": true,
                    "selections": [
                      (v100/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SearchBrowseFilterValue",
                        "kind": "LinkedField",
                        "name": "values",
                        "plural": true,
                        "selections": [
                          (v112/*: any*/),
                          (v114/*: any*/),
                          (v44/*: any*/),
                          (v101/*: any*/),
                          (v59/*: any*/),
                          (v113/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "stats",
                            "kind": "LinkedField",
                            "name": "stats",
                            "plural": true,
                            "selections": [
                              (v77/*: any*/),
                              (v140/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v117/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "FilterPropertiesType",
                            "kind": "LinkedField",
                            "name": "properties",
                            "plural": false,
                            "selections": [
                              (v115/*: any*/),
                              (v116/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "min",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "max",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v102/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v118/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalCount",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isAttributePage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SortType",
                    "kind": "LinkedField",
                    "name": "sort",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SearchBrowseSortAvailableOptions",
                        "kind": "LinkedField",
                        "name": "options",
                        "plural": true,
                        "selections": [
                          (v101/*: any*/),
                          (v112/*: any*/),
                          (v44/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "searchRequestId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ItemSearchSponsored",
                    "kind": "LinkedField",
                    "name": "sponsored",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ItemSearchSponsoredMetadata",
                        "kind": "LinkedField",
                        "name": "metadata",
                        "plural": true,
                        "selections": [
                          (v138/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "impressionTrackerLink",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "clickTrackerLink",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Item",
                        "kind": "LinkedField",
                        "name": "items",
                        "plural": true,
                        "selections": [
                          (v34/*: any*/),
                          (v36/*: any*/),
                          (v40/*: any*/),
                          (v141/*: any*/),
                          (v38/*: any*/),
                          (v37/*: any*/),
                          (v35/*: any*/),
                          (v68/*: any*/),
                          (v129/*: any*/),
                          (v142/*: any*/),
                          (v121/*: any*/),
                          (v123/*: any*/),
                          (v41/*: any*/),
                          (v42/*: any*/),
                          (v43/*: any*/),
                          (v45/*: any*/),
                          (v46/*: any*/),
                          (v49/*: any*/),
                          (v54/*: any*/),
                          (v56/*: any*/),
                          (v120/*: any*/),
                          (v69/*: any*/),
                          (v70/*: any*/),
                          (v71/*: any*/),
                          (v73/*: any*/),
                          (v74/*: any*/),
                          (v75/*: any*/),
                          (v79/*: any*/),
                          (v126/*: any*/),
                          (v85/*: any*/),
                          (v86/*: any*/),
                          (v127/*: any*/),
                          (v128/*: any*/),
                          (v143/*: any*/),
                          (v39/*: any*/),
                          (v130/*: any*/),
                          (v144/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ItemSearchQueryEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ItemSearchResult",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Item",
                            "kind": "LinkedField",
                            "name": "item",
                            "plural": false,
                            "selections": [
                              (v36/*: any*/),
                              (v40/*: any*/),
                              (v141/*: any*/),
                              (v38/*: any*/),
                              (v37/*: any*/),
                              (v35/*: any*/),
                              (v68/*: any*/),
                              (v129/*: any*/),
                              (v142/*: any*/),
                              (v121/*: any*/),
                              (v123/*: any*/),
                              (v41/*: any*/),
                              (v42/*: any*/),
                              (v43/*: any*/),
                              (v45/*: any*/),
                              (v46/*: any*/),
                              (v49/*: any*/),
                              (v54/*: any*/),
                              (v56/*: any*/),
                              {
                                "alias": null,
                                "args": (v108/*: any*/),
                                "concreteType": "ItemDisplayPriceType",
                                "kind": "LinkedField",
                                "name": "displayPrice",
                                "plural": true,
                                "selections": [
                                  (v61/*: any*/),
                                  (v62/*: any*/),
                                  (v63/*: any*/),
                                  (v64/*: any*/),
                                  (v65/*: any*/),
                                  (v66/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v69/*: any*/),
                              (v70/*: any*/),
                              (v71/*: any*/),
                              (v73/*: any*/),
                              (v74/*: any*/),
                              (v75/*: any*/),
                              (v79/*: any*/),
                              (v126/*: any*/),
                              (v85/*: any*/),
                              (v86/*: any*/),
                              (v127/*: any*/),
                              (v128/*: any*/),
                              (v143/*: any*/),
                              (v130/*: any*/),
                              (v144/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": "relatedItems",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 12
                      }
                    ],
                    "concreteType": "youMayAlsoLikeConnection",
                    "kind": "LinkedField",
                    "name": "youMayAlsoLike",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "youMayAlsoLikeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ItemSearchResult",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Item",
                                "kind": "LinkedField",
                                "name": "item",
                                "plural": false,
                                "selections": [
                                  (v34/*: any*/),
                                  (v36/*: any*/),
                                  (v41/*: any*/),
                                  (v42/*: any*/),
                                  (v43/*: any*/),
                                  (v45/*: any*/),
                                  (v46/*: any*/),
                                  (v49/*: any*/),
                                  (v54/*: any*/),
                                  (v56/*: any*/),
                                  (v120/*: any*/),
                                  (v68/*: any*/),
                                  (v121/*: any*/),
                                  (v123/*: any*/),
                                  (v69/*: any*/),
                                  (v70/*: any*/),
                                  (v71/*: any*/),
                                  (v73/*: any*/),
                                  (v74/*: any*/),
                                  (v75/*: any*/),
                                  (v79/*: any*/),
                                  (v126/*: any*/),
                                  (v85/*: any*/),
                                  (v86/*: any*/),
                                  (v127/*: any*/),
                                  (v128/*: any*/),
                                  (v143/*: any*/),
                                  (v129/*: any*/),
                                  (v40/*: any*/),
                                  (v141/*: any*/),
                                  (v38/*: any*/),
                                  (v37/*: any*/),
                                  (v35/*: any*/),
                                  (v142/*: any*/),
                                  (v39/*: any*/),
                                  (v130/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v39/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "youMayAlsoLike(first:12)"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "soldResultsConnection",
                    "kind": "LinkedField",
                    "name": "soldResults",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "soldResultsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ItemSearchResult",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Item",
                                "kind": "LinkedField",
                                "name": "item",
                                "plural": false,
                                "selections": [
                                  (v34/*: any*/),
                                  (v36/*: any*/),
                                  (v40/*: any*/),
                                  (v141/*: any*/),
                                  (v38/*: any*/),
                                  (v37/*: any*/),
                                  (v35/*: any*/),
                                  (v68/*: any*/),
                                  (v129/*: any*/),
                                  (v142/*: any*/),
                                  (v121/*: any*/),
                                  (v123/*: any*/),
                                  (v41/*: any*/),
                                  (v42/*: any*/),
                                  (v43/*: any*/),
                                  (v45/*: any*/),
                                  (v46/*: any*/),
                                  (v49/*: any*/),
                                  (v54/*: any*/),
                                  (v56/*: any*/),
                                  (v120/*: any*/),
                                  (v69/*: any*/),
                                  (v70/*: any*/),
                                  (v71/*: any*/),
                                  (v73/*: any*/),
                                  (v74/*: any*/),
                                  (v75/*: any*/),
                                  (v79/*: any*/),
                                  (v126/*: any*/),
                                  (v85/*: any*/),
                                  (v86/*: any*/),
                                  (v127/*: any*/),
                                  (v128/*: any*/),
                                  (v143/*: any*/),
                                  (v39/*: any*/),
                                  (v130/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v39/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clearAppliedFiltersLinkReference",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "first",
                        "variableName": "interiorPhotosCount"
                      },
                      {
                        "kind": "Literal",
                        "name": "template",
                        "value": "tradeProjectPhoto.search"
                      }
                    ],
                    "concreteType": "SearchBrowseInteriorPhotosModule",
                    "kind": "LinkedField",
                    "name": "interiorPhotos",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "InteriorPhotosModulePhotoSearchParam",
                        "kind": "LinkedField",
                        "name": "photoSearchParam",
                        "plural": false,
                        "selections": [
                          (v100/*: any*/),
                          (v140/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "photosBrowseLink",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "InteriorPhotosModuleSearchBrowseBackLink",
                        "kind": "LinkedField",
                        "name": "searchBrowseBackLink",
                        "plural": false,
                        "selections": [
                          (v44/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "linkUrl",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LinkData",
                        "kind": "LinkedField",
                        "name": "relatedPhotoSearchLinks",
                        "plural": true,
                        "selections": [
                          (v47/*: any*/),
                          (v78/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PhotosSearchBrowsePhoto",
                        "kind": "LinkedField",
                        "name": "photos",
                        "plural": true,
                        "selections": [
                          (v39/*: any*/),
                          (v47/*: any*/),
                          (v100/*: any*/),
                          (v137/*: any*/),
                          (v34/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PhotoLike",
                            "kind": "LinkedField",
                            "name": "photoLike",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "totalPhotoLikes",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v52/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": "editorial",
                    "args": (v145/*: any*/),
                    "concreteType": "WordpressArticlesType",
                    "kind": "LinkedField",
                    "name": "editorialArticles",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WordpressArticleType",
                        "kind": "LinkedField",
                        "name": "articles",
                        "plural": true,
                        "selections": [
                          (v146/*: any*/),
                          (v36/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "WordpressArticleImageType",
                            "kind": "LinkedField",
                            "name": "imageUrl",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "thumb",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "medium",
                                "storageKey": null
                              },
                              (v147/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v134/*: any*/),
                          (v94/*: any*/),
                          (v148/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v52/*: any*/)
                    ],
                    "storageKey": "editorialArticles(first:8)"
                  },
                  {
                    "alias": null,
                    "args": (v145/*: any*/),
                    "concreteType": "WordpressArticlesType",
                    "kind": "LinkedField",
                    "name": "editorialArticles",
                    "plural": false,
                    "selections": [
                      (v52/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WordpressArticleType",
                        "kind": "LinkedField",
                        "name": "articles",
                        "plural": true,
                        "selections": [
                          (v146/*: any*/),
                          (v36/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "WordpressArticleImageType",
                            "kind": "LinkedField",
                            "name": "imageUrl",
                            "plural": false,
                            "selections": [
                              (v147/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v134/*: any*/),
                          (v94/*: any*/),
                          (v148/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "editorialArticles(first:8)"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FeaturedDeal",
                    "kind": "LinkedField",
                    "name": "featuredDeal",
                    "plural": false,
                    "selections": [
                      (v72/*: any*/),
                      (v121/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ConvertedAmountList",
                        "kind": "LinkedField",
                        "name": "convertedAmountList",
                        "plural": true,
                        "selections": [
                          (v58/*: any*/),
                          (v59/*: any*/),
                          (v49/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "previewId",
                        "variableName": "previewId"
                      }
                    ],
                    "concreteType": "L1Module",
                    "kind": "LinkedField",
                    "name": "l1Module",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "L1ModuleItemTile",
                        "kind": "LinkedField",
                        "name": "banners",
                        "plural": true,
                        "selections": (v149/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "L1ModuleItemTile",
                        "kind": "LinkedField",
                        "name": "firstTile",
                        "plural": false,
                        "selections": (v149/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "L1ModuleItemTile",
                        "kind": "LinkedField",
                        "name": "secondTile",
                        "plural": false,
                        "selections": (v149/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LinkData",
                    "kind": "LinkedField",
                    "name": "popularSearchesLink",
                    "plural": false,
                    "selections": [
                      (v78/*: any*/),
                      (v47/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InternalBuyLinkType",
                    "kind": "LinkedField",
                    "name": "relatedPages",
                    "plural": true,
                    "selections": (v150/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "relatedCreatorsConnection",
                    "kind": "LinkedField",
                    "name": "relatedCreators",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "relatedCreatorsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "RelatedCreatorType",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v49/*: any*/),
                              {
                                "alias": "shopAllTitle",
                                "args": null,
                                "kind": "ScalarField",
                                "name": "title",
                                "storageKey": null
                              },
                              {
                                "alias": "creatorName",
                                "args": null,
                                "kind": "ScalarField",
                                "name": "pageObjectName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "items",
                                "plural": true,
                                "selections": [
                                  (v104/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v36/*: any*/),
                                      (v42/*: any*/),
                                      {
                                        "alias": null,
                                        "args": (v87/*: any*/),
                                        "concreteType": "ItemPhoto",
                                        "kind": "LinkedField",
                                        "name": "photos",
                                        "plural": true,
                                        "selections": [
                                          (v90/*: any*/),
                                          (v39/*: any*/)
                                        ],
                                        "storageKey": "photos(limit:1)"
                                      }
                                    ],
                                    "type": "Item",
                                    "abstractKey": null
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v39/*: any*/)
                                    ],
                                    "type": "Node",
                                    "abstractKey": "__isNode"
                                  }
                                ],
                                "storageKey": null
                              },
                              (v39/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InternalBuyLinkType",
                    "kind": "LinkedField",
                    "name": "internalBuyLinks",
                    "plural": true,
                    "selections": (v150/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "faqHeader",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "QuestionType",
                    "kind": "LinkedField",
                    "name": "questionsAnswers",
                    "plural": true,
                    "selections": [
                      (v39/*: any*/),
                      (v137/*: any*/),
                      (v36/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "answerCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AnswerType",
                        "kind": "LinkedField",
                        "name": "answers",
                        "plural": true,
                        "selections": [
                          (v34/*: any*/),
                          (v78/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "formattedCreatedDate",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Seller",
                            "kind": "LinkedField",
                            "name": "author",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SellerPreferences",
                                "kind": "LinkedField",
                                "name": "sellerPreferences",
                                "plural": false,
                                "selections": [
                                  (v151/*: any*/),
                                  (v101/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "scrollLogo",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v39/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "condition": "isTrade",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": (v131/*: any*/),
                        "concreteType": "SearchBrowsePriceTile",
                        "kind": "LinkedField",
                        "name": "priceTiles",
                        "plural": true,
                        "selections": [
                          (v59/*: any*/),
                          (v139/*: any*/),
                          (v137/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SearchBrowseFacets",
                        "kind": "LinkedField",
                        "name": "facets",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "FacetValueType",
                            "kind": "LinkedField",
                            "name": "location",
                            "plural": true,
                            "selections": [
                              (v100/*: any*/),
                              (v151/*: any*/),
                              (v39/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v39/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ],
            "storageKey": null
          },
          (v39/*: any*/),
          {
            "condition": "fetchRegionalInfo",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": "regionsInfo",
                "args": (v154/*: any*/),
                "concreteType": "RegionalInfo",
                "kind": "LinkedField",
                "name": "regionalInfo",
                "plural": true,
                "selections": (v155/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v154/*: any*/),
                "concreteType": "RegionalInfo",
                "kind": "LinkedField",
                "name": "regionalInfo",
                "plural": true,
                "selections": (v155/*: any*/),
                "storageKey": null
              }
            ]
          },
          {
            "condition": "isTrade",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v156/*: any*/),
                "concreteType": "Region",
                "kind": "LinkedField",
                "name": "regions",
                "plural": true,
                "selections": [
                  (v122/*: any*/),
                  (v101/*: any*/),
                  (v39/*: any*/)
                ],
                "storageKey": "regions(address:{\"country\":\"us\"},regionBoundaryTypes:[\"METROPOLITAN\",\"DISTRICT\",\"DOMESTIC\"],regionType:\"ITEM_LOCATION\")"
              },
              {
                "condition": "isClient",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      (v152/*: any*/),
                      (v97/*: any*/),
                      (v153/*: any*/)
                    ],
                    "concreteType": "ShippingFilterType",
                    "kind": "LinkedField",
                    "name": "shippingFilters",
                    "plural": true,
                    "selections": [
                      (v100/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "FilterValueType",
                        "kind": "LinkedField",
                        "name": "values",
                        "plural": true,
                        "selections": [
                          (v101/*: any*/),
                          (v44/*: any*/),
                          (v112/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ]
              },
              {
                "condition": "isTrade",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": (v156/*: any*/),
                    "concreteType": "Region",
                    "kind": "LinkedField",
                    "name": "regions",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RegionBoundary",
                        "kind": "LinkedField",
                        "name": "regionBoundary",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "RegionBoundaryType",
                            "kind": "LinkedField",
                            "name": "type",
                            "plural": false,
                            "selections": [
                              (v100/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "regions(address:{\"country\":\"us\"},regionBoundaryTypes:[\"METROPOLITAN\",\"DISTRICT\",\"DOMESTIC\"],regionType:\"ITEM_LOCATION\")"
                  }
                ]
              }
            ]
          },
          {
            "condition": "isClient",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": "locations",
                "args": [
                  (v152/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "facetLimit",
                    "value": 10
                  },
                  {
                    "kind": "Literal",
                    "name": "facetName",
                    "value": "location"
                  },
                  (v97/*: any*/),
                  (v153/*: any*/)
                ],
                "concreteType": "ItemFacetsQueryConnection",
                "kind": "LinkedField",
                "name": "itemFacetSearch",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ItemFacetsQueryEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "FilterValueType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v113/*: any*/),
                          (v44/*: any*/),
                          (v101/*: any*/),
                          (v112/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "guestId",
                    "variableName": "rerankGuestId"
                  },
                  {
                    "kind": "Variable",
                    "name": "userId",
                    "variableName": "rerankUserId"
                  }
                ],
                "kind": "ScalarField",
                "name": "isEligibleForRerank",
                "storageKey": null
              }
            ]
          },
          {
            "condition": "fetchAuctionsItemSearch",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": "auctionsItemSearch",
                "args": [
                  (v95/*: any*/),
                  {
                    "kind": "Variable",
                    "name": "uriRef",
                    "variableName": "auctionsItemSearchUriRef"
                  },
                  (v99/*: any*/)
                ],
                "concreteType": "ItemSearchQueryConnection",
                "kind": "LinkedField",
                "name": "itemSearch",
                "plural": false,
                "selections": (v53/*: any*/),
                "storageKey": null
              }
            ]
          },
          {
            "condition": "fetchUser",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v157/*: any*/),
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v34/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isVerifiedTrade",
                    "storageKey": null
                  },
                  (v104/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TradeFirmType",
                    "kind": "LinkedField",
                    "name": "tradeFirm",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TradeFirmRewards",
                        "kind": "LinkedField",
                        "name": "rewards",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ParentProgram",
                            "kind": "LinkedField",
                            "name": "currentProgram",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "LoyaltyProgram",
                                "kind": "LinkedField",
                                "name": "programInfo",
                                "plural": false,
                                "selections": [
                                  (v102/*: any*/),
                                  (v39/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v39/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "condition": "isTrade",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "daysToExpiration",
                            "value": 7
                          },
                          {
                            "kind": "Literal",
                            "name": "promoCodes",
                            "value": [
                              "TRADEWELCOME",
                              "TRADE1STWELCOME"
                            ]
                          }
                        ],
                        "concreteType": "Promotion",
                        "kind": "LinkedField",
                        "name": "expiringPromotion",
                        "plural": false,
                        "selections": [
                          (v158/*: any*/),
                          (v102/*: any*/),
                          (v39/*: any*/)
                        ],
                        "storageKey": "expiringPromotion(daysToExpiration:7,promoCodes:[\"TRADEWELCOME\",\"TRADE1STWELCOME\"])"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TradeFirmRewards",
                        "kind": "LinkedField",
                        "name": "tradeFirmRewards",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": (v157/*: any*/),
                            "concreteType": "currentPromoSegmentType",
                            "kind": "LinkedField",
                            "name": "currentPromoSegment",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Promotion",
                                "kind": "LinkedField",
                                "name": "promotion",
                                "plural": false,
                                "selections": [
                                  (v102/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "buyerExpirationDate",
                                    "storageKey": null
                                  },
                                  (v39/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "expiringPromoSegmentType",
                            "kind": "LinkedField",
                            "name": "expiringPromoSegment",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "expiringSegmentCode",
                                "storageKey": null
                              },
                              (v158/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "canShowSellerName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "canShowFolder",
                    "storageKey": null
                  },
                  {
                    "condition": "fetchUser",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": (v159/*: any*/),
                        "concreteType": "favoritesConnection",
                        "kind": "LinkedField",
                        "name": "favorites",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "favoritesEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Favorite",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v34/*: any*/),
                                  (v44/*: any*/),
                                  (v39/*: any*/),
                                  {
                                    "kind": "ClientExtension",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "__id",
                                        "storageKey": null
                                      }
                                    ]
                                  },
                                  (v104/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cursor",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v159/*: any*/),
                        "filters": [],
                        "handle": "connection",
                        "key": "SharedSaveSearchContext_favorites",
                        "kind": "LinkedHandle",
                        "name": "favorites"
                      }
                    ]
                  },
                  {
                    "alias": "canShowInternalSellerName",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "name",
                        "value": "VIEW_SELLER_NAME"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "hasPermission",
                    "storageKey": "hasPermission(name:\"VIEW_SELLER_NAME\")"
                  },
                  {
                    "alias": "canShowStorefrontOnlyBadge",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "name",
                        "value": "VIEW_STOREFRONT_ONLY_BADGE"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "hasPermission",
                    "storageKey": "hasPermission(name:\"VIEW_STOREFRONT_ONLY_BADGE\")"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserPreferences",
                    "kind": "LinkedField",
                    "name": "preferences",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "measurementUnit",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AddressBook",
                    "kind": "LinkedField",
                    "name": "addressBook",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Address",
                        "kind": "LinkedField",
                        "name": "personalAddress",
                        "plural": false,
                        "selections": (v160/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Address",
                        "kind": "LinkedField",
                        "name": "proAddress",
                        "plural": false,
                        "selections": (v160/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Address",
                        "kind": "LinkedField",
                        "name": "addressList",
                        "plural": true,
                        "selections": (v160/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": "showStrategicPartnerFilter",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "name",
                        "value": "STRATEGIC_PARTNER_FILTERING"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "hasPermission",
                    "storageKey": "hasPermission(name:\"STRATEGIC_PARTNER_FILTERING\")"
                  },
                  {
                    "alias": "hasMerchandizingPermission",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "name",
                        "value": "BUYER_FE_MERCH_TOOL"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "hasPermission",
                    "storageKey": "hasPermission(name:\"BUYER_FE_MERCH_TOOL\")"
                  },
                  {
                    "alias": "hasBasicInternalPermission",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "name",
                        "value": "BASIC_INTERNAL"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "hasPermission",
                    "storageKey": "hasPermission(name:\"BASIC_INTERNAL\")"
                  },
                  (v39/*: any*/)
                ],
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-finding/479.0.0-2024-09-12T15:05:45.473Z/sbRespRootQuery",
    "metadata": {},
    "name": "sbRespRootQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "74a2e688af4dab89e890c0b82e2e8ee6";

export default node;
